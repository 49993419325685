<template>
	<div>
		<b-card no-body class="mb-1">
			<div class="m-1">
				<div :class="[$store.getters['app/currentBreakPoint'] !== 'xs' ? 'mx-1 mb-2' : '']">
					<b-row>
						<b-col cols="12" md="9">
							<b-row>
								<b-col cols="12" md="6">
									<label for="example-datepicker"
										>Fecha del {{ type == 1 ? "gasto" : "depósito" }}</label
									>
									<b-form-datepicker
										id="example-datepicker"
										v-model="transactionObject.transaction_date"
										class="mb-2"
										placeholder="Fecha"
										:date-format-options="{
											year: 'numeric',
											month: 'numeric',
											day: 'numeric',
										}"
										:disabled="isViewingOrEditing"
										locale="es"
									></b-form-datepicker>
								</b-col>
								<b-col cols="12" md="6"></b-col>
							</b-row>
						</b-col>
						<b-col cols="12" md="3" class="text-right">
							<label for="total">Total</label>
							<span id="total"
								><h3>
									<money
										class="input-form text-right"
										v-model="total"
										v-bind="money"
										id="bank_detail"
										disabled
										style="background: transparent; border: none; width: 90%"
									></money></h3
							></span>
						</b-col>
						<b-col cols="12" md="12">
							<b-table
								bordered
								:items="transactionObject.details"
								thead-class="b-vue-th"
								:fields="arrayColumns"
								small
								table-class="visible-overflow"
								stacked="md"
							>
								<template #cell(reason)="data">
									<v-select
										v-model="data.item.reason"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="arrayReasons"
										:clearable="false"
										label="reason"
										:reduce="(option) => option"
										:disabled="isViewingOrEditing"
									>
										<template v-slot:option="option">
											<div class="d-flex justify-content-between align-items-center">
												<span :style="`margin-left: ${option.parent_id ? '10' : '0'}px`"
													>{{ option.reason }}
												</span>
												<span v-if="option.parent_id">
													Sub-motivo de {{ option.parent_reason }}
												</span>
											</div>
										</template>
									</v-select>
								</template>
								<template #cell(description)="data">
									<b-form-input
										placeholder="Descripción"
										v-model="data.item.description"
										:disabled="isViewingOrEditing"
									></b-form-input>
								</template>
								<template #cell(amount)="data">
									<money
										class="form-control text-center"
										v-model="data.item.amount"
										v-bind="money"
										:disabled="isViewingOrEditing"
										id="bank_detail"
									></money>
								</template>
								<template #cell(actions)="data">
									<feather-icon
										icon="TrashIcon"
										:class="[
											'cursor-pointer',
											data.index == 0 || isViewingOrEditing ? 'pointer-none' : '',
										]"
										@click="deleteLine(data.index)"
										size="20"
									/>
								</template>
							</b-table>
						</b-col>
						<b-col cols="12" md="12" class="mt-2">
							<b-row>
								<b-col cols="12" md="6" v-if="!isViewingOrEditing">
									<div>
										<b-button variant="outline-primary" @click="addNewLines">
											Agregar lineas
										</b-button>
									</div>
								</b-col>
								<b-col
									cols="12"
									md="6"
									class="text-right d-flex align-items-center justify-content-end"
								>
									<span><h5>Total:</h5></span>
									<span class="ml-2"
										><h5>
											<money
												class="input-form"
												v-model="total"
												v-bind="money"
												id="bank_detail"
												disabled
												style="background: transparent; border: none"
											></money></h5
									></span>
								</b-col>
							</b-row>
						</b-col>
						<b-col cols="12" md="4" class="mt-2">
							<label for="example-datepicker">Memo</label>
							<b-form-textarea
								id="textarea"
								placeholder="Memo..."
								rows="3"
								v-model="transactionObject.memo"
								:disabled="isViewingOrEditing"
							></b-form-textarea>
						</b-col>
					</b-row>
					<template v-if="!$route.params.transaction_id">
						<b-button
							variant="primary"
							class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-1"
							:block="$store.getters['app/currentBreakPoint'] === 'xs'"
							v-if="!show"
							@click="upload(2)"
						>
							Guardar
						</b-button>
						<b-button variant="primary" disabled class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-1" v-else>
							<b-spinner small />
							Loading...
						</b-button>
						<!-- <b-button
              variant="outline-secondary"
              class="mt-1"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"

            >
              Resetear
            </b-button> -->
					</template>
				</div>
			</div>
		</b-card>
	</div>
</template>

<script>
import vSelect from "vue-select";
import { Money } from "v-money";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import reasonsService from "@/services/reasons.service";
export default {
	props: {
		type: [Number, Object],
	},
	components: {
		vSelect,
		Money,
	},
	directives: {
		Ripple,
	},
	computed: {
		total: {
			get() {
				if (this.transactionObject.details.length > 0) {
					return this.transactionObject.details.reduce((accum, obj) => accum + obj.amount, 0);
				} else {
					return 0;
				}
			},
			set(value) {
				return value;
			},
		},
		isAtLeastOneItemAdded() {
			let counter = 0;
			for (let i = 0; i < this.transactionObject.details.length; i++) {
				if (this.transactionObject.details[i].reason != null) {
					counter = counter + 1;
				}
				if (this.transactionObject.details[i].description) {
					counter = counter + 1;
				}
				if (this.transactionObject.details[i].amount != 0) {
					counter = counter + 1;
				}
			}
			if (counter > 0) {
				return true;
			} else {
				return false;
			}
		},
		isViewingOrEditing() {
			if (this.$route.params.transaction_id) {
				return true;
			} else {
				return false;
			}
		},
	},
	async mounted() {
		await this.getReasonsByModule();
		this.transactionObject.transaction_date = moment().format("YYYY-MM-DD");
		if (this.$route.params.transaction_id) {
			this.getTransaction();
		}
	},
	data() {
		return {
			transactionObject: {
				transaction_id: null,
				transaction_date: null,
				type: 1,
				memo: null,
				total: 0,
				status: 2,
				created_by: null,
				details: [
					{ description: null, reason: null, amount: 0, id: null },
					{ description: null, reason: null, amount: 0, id: null },
					{ description: null, reason: null, amount: 0, id: null },
					{ description: null, reason: null, amount: 0, id: null },
				],
				files: [],
			},
			arrayColumns: [
				{
					key: "reason",
					label: "Motivo",
					class: "text-center col-md-4",
				},
				{
					key: "description",
					label: "Descripción",
					class: "text-center col-md-3",
				},
				{
					key: "amount",
					label: "Monto",
					class: "text-center col-md-3",
				},
				{
					key: "actions",
					label: "Acciones",
					class: "text-center col-md-2 align-items-center",
				},
			],
			reasons: [],
			money: {
				decimal: ".",
				thousands: ",",
				prefix: "S/. ",
				precision: 2,
				masked: false,
			},
			arrayReasons: [],
			show: false,
			deleteds: [],
			deletedFiles: [],
		};
	},
	methods: {
		getTransaction() {
			axios
				.post("/api/administrative/get-selected-one", {
					transaction_id: this.$route.params.transaction_id,
				})
				.then((res) => {
					this.transactionObject = res.data[0];
					for (let i = 0; i < this.transactionObject.details.length; i++) {
						let motive = this.arrayReasons.filter(
							(motive) => motive.id == this.transactionObject.details[i].motive
						);
						this.transactionObject.details[i].reason = motive[0];
					}
				});
		},
		async getReasonsByModule() {
			const data = await reasonsService.getByModule(3);
			this.arrayReasons = data;
		},
		customLabel({ print_on_check_as, display_name_as, company }) {
			return print_on_check_as ? display_name_as : company;
		},
		addNewLines() {
			this.transactionObject.details.push({
				description: null,
				reason: null,
				amount: 0,
				id: null,
			});
		},
		showToast(variant, position, title, icon, text) {
			this.$toast(
				{
					component: ToastificationContent,
					props: {
						title,
						icon,
						text,
						variant,
					},
				},
				{
					position,
				}
			);
		},
		deleteLine(index) {
			this.transactionObject.details.splice(index, 1);
		},
		upload(status) {
			this.transactionObject.type = this.type;
			if (this.isAtLeastOneItemAdded) {
				if (status == 1) {
					const formData = new FormData();
					this.files.forEach((file) => {
						formData.append("images[]", file, file.name);
					});
					formData.append("header", JSON.stringify(this.expenseObject));
					formData.append("created_by", this.global.layout.id);
					formData.append("status", status);
					formData.append("total", this.totalAmount);
					axios.post("/api/administrative/save", formData).then((response) => {
						this.showToast("success", "top-right", "Felicidades", "CheckIcon", "Transacción registrada!");
						if (this.deleteds.length > 0) {
							axios
								.post("/api/delete-transaction-detail", {
									deleteds: this.deleteds,
								})
								.then((res) => {});
						}
						if (this.deletedFiles.length > 0) {
							axios
								.post("/api/delete-transaction-file", {
									deleted_files: this.deletedFiles,
								})
								.then((res) => {});
						}
					});
				} else {
					this.$swal({
						title: "Estas seguro?",
						text: "¡No podrás revertir esto!",
						icon: "warning",
						showCancelButton: true,
						cancelButtonText: "Cancelar",
						confirmButtonText: "Confirmar",
						customClass: {
							cancelButton: "btn btn-outline-danger ml-1",
							confirmButton: "btn btn-primary",
						},
						buttonsStyling: false,
					}).then((result) => {
						if (result.value) {
							const formData = new FormData();
							this.transactionObject.files.forEach((file) => {
								formData.append("images[]", file, file.name);
							});
							formData.append("header", JSON.stringify(this.transactionObject));
							formData.append("created_by", this.currentUser.user_id);
							formData.append("status", status);
							formData.append("total", this.total);
							axios.post("/api/administrative/save", formData).then((response) => {
								this.showToast(
									"success",
									"top-right",
									"Felicidades",
									"CheckIcon",
									`${this.type == 1 ? "Gasto" : "Depósito"} confirmado!`
								);
								if (this.type == 1) {
									this.$router.push("/administrativo/gastos");
								} else if (this.type == 2) {
									this.$router.push("/administrativo/depositos");
								}
							});
						}
					});
				}
			} else {
				this.$swal({
					icon: "error",
					title: "Algo no está bien!",
					text: "Al menos debe llenar un elemento.",
					customClass: {
						confirmButton: "btn btn-primary",
					},
					buttonsStyling: false,
				});
			}
		},
	},
};
</script>

<style scoped>
.pointer-none {
	pointer-events: none;
	opacity: 0.7;
}
.visible-overflow {
	overflow: visible;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
